import React from "react";
import useRouter from "src/core/common/hooks/useRouter";
import {isClient} from "src/server/utils/isClient";
import useThemeConfig from "src/themes/useThemeConfig";
import Modal from "src/core/common/components/modules/modal/Modal";
import styled from "styled-components";
import {ModalExternalContainer} from "src/core/common/components/modules/modal/modalComponents";

function ProductDetail({onRequestClose}) {
  const router = useRouter();
  const {productId, noBrandProductId} = router.query;
  const resolvedProductId = productId || noBrandProductId;

  const config = useThemeConfig();
  const ThemedProductDetail = config.components.ProductDetail;

  if (!isClient && resolvedProductId) {
    return <ThemedProductDetail productId={resolvedProductId} />;
  }
  return (
    <ProductDetailModal
      isOpen={Boolean(resolvedProductId)}
      onRequestClose={onRequestClose}
    >
      {resolvedProductId && (
        <ThemedProductDetail key={resolvedProductId} productId={resolvedProductId} />
      )}
    </ProductDetailModal>
  );
}

const ProductDetailModal = ({children, isOpen, onRequestClose}) => {
  const _children = Array.isArray(children) ? children : children ? [children] : [];
  return (
    <Modal isOpen={isOpen} container={VerticalContainer} onRequestClose={onRequestClose}>
      {({closeModal}) =>
        _children.map((element, index) => (
          <React.Fragment key={index}>
            {React.cloneElement(element, {
              onAddItem: closeModal,
            })}
          </React.Fragment>
        ))
      }
    </Modal>
  );
};

const VerticalContainer = styled(ModalExternalContainer)`
  display: block;
`;

export default ProductDetail;
